import React from "react"

function ContactForm() {
  return (
    <div className="contact-content">
      <form name="contact" method="POST" data-netlify="true">
        <input type="hidden" name="form-name" value="contact" />
        <div className="row">
          <div className="col-4">
            <p>
              Name: <input type="text" name="name" />
            </p>
          </div>
          <div className="col-4">
            <p>
              Email: <input type="email" name="email" />
            </p>
          </div>
          <div className="col-4">
            <p>
              Telephone: <input type="tel" name="telephone" />
            </p>
          </div>
        </div>
        <p>
          Message: <textarea name="message" rows="8" />
        </p>
        <p>
          <button type="submit">Send</button>
        </p>
      </form>
    </div>
  )
}

export default ContactForm
