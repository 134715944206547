import React from "react"

function ContactInfo() {
  return (
    <div className="post-content row">
      <div className="col-6 contact-info">
        <h6>IGL DESIGN</h6>
        <p>
          43-1, Block D, Zenith Corporate Park, Jalan SS7/26 Kelana Jaya 47301
          Petaling Jaya, Selangor
        </p>
      </div>
      {/* <div className="col-4 contact-info">
        <h6>Business Hours</h6>
        <p className="contact-info-p">
          <strong>AM</strong>: 11:00~14:30
        </p>
        <p>
          <strong>PM</strong>: 17:00~01:00
        </p>
      </div> */}
      <div className="col-6 contact-info">
        <h6>contact</h6>
        <p className="contact-info-p">
          <strong>tel</strong>:{" "}
          <a href={`tel:+6 012-218 8851`}>+6 012-218 8851</a>
        </p>
        {/* <p className="contact-info-p">
          <strong>Fax</strong>:{" "}
          <a href={`tel:+6 03-1234 1234`}>+6 03-1234 1234</a>
        </p> */}
        <p>
          <strong>email</strong>:{" "}
          <a href={`mailto:contact@igldesign.com.my`}>garylim.igl@gmail.com</a>
        </p>
      </div>
    </div>
  )
}

export default ContactInfo
